import wapcosiit from "../assets/wapcosiit.png";
import mssw from "../assets/mssw.png";
import corbonemission from "../assets/corbonemission.png";
import dhanvan from "../assets/Dhanvan.png";
import iServify from "../assets/iServify.png";
import steel from "../assets/steel.png";

export default function Projects(){

  const config = {

Projects:[
  {
image:wapcosiit,
description:"Develop a  Construction PMS for planning and scheduling to budgeting and resource allocation. observation maintenance, Planned vs actual, automated man days calculation etc...",
link:"http://iitmanagement.co.in/",
}, {
image:mssw,
description:"Develop a  Educational ERP system involves  on core modules like Admission, Student profile, Accounts, Payment integration etc...",
link:"https://admission.mssw.in/",
}, {
image:corbonemission,
description:"Develop a Carbon Footprint Calculator application can be a meaningful project to raise awareness about environmental impact and encourage sustainable practices...",
link:"http://aseansmeclimateguide.com/FPCalculator/footPrintCalculator",
}


]


  };
    const config1 = {

Projects:[
 {
image:iServify,
description:"iServify is a complete SaaS based multi business service booking, event management, facility management, Property Management, that gives your users the ability to create and manage bookings",
link:"http://aseansmeclimateguide.com/FPCalculator/footPrintCalculator",
},,{
image:steel,
description:"Lead management system Its about lead conversion sales data purchase data analytics role management hr module employee management so on",
link:"http://steel.anterstech.com",
},{
  image:dhanvan,
  description:"Doctor appointntment booking app having more functionality like chat with doctor and call with doctor through android app",
  link:"https://dhanvan.in",
  },



]


  }
 
  
return <section id="projects" className="flex flex-col py-10 px-5 justify-center defaultTop">
    <div className="w-full ">
    <div className="flex flex-col px-5 ">
    <h2 className="text-4xl font-my-des-font py-2 text-center" ><span className="text-white">My</span> Projects </h2>
  <p className="text-center text-white py-5 ">These are some of my project. I have built with PHP CODEIGNITER and LARAVEL </p>
    </div>
  </div>



  <div className="w-full  ">
  <div className="flex flex-col md:flex-row px-10 gap-10">
   
   {config.Projects.map((Project, index)=>(


<div key={index} className="relative">
    <img className="h-[210px]" src={Project.image} />
    <div className="project-desc">
      <p className="text-center text-sm text-white px-5 py-5">{Project.description}</p>
<div className="flex justify-center py-2">
      <a target="_blank"  href={Project.link}  className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">View</a>
      </div>
    </div>
    </div>

))} 
 
</div>
 </div>
 <div className="w-full  pt-4">
  <div className="flex flex-col md:flex-row px-10 gap-10 ">
   
   {config1.Projects.map((Project, index)=>(


<div key={index} className="relative">
    <img className="h-[210px]" src={Project.image} />
    <div className="project-desc">
      <p className="text-center text-sm text-white px-5 py-5">{Project.description}</p>
<div className="flex justify-center py-2">
      <a target="_blank"  href={Project.link}  className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">View</a>
      </div>
    </div>
    </div>

))} 
 
</div>
 </div>
</section>;
} 